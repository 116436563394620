<template>
  <div>
    <ExportBuilder></ExportBuilder>
  </div>
</template>
<script>
import ExportBuilder from "./ExportBuilder.vue";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    ExportBuilder,
  },
  name: "ExportIndividualWageTypes",
  data() {
    return {
      mode: "individual-wagetypes",
    };
  },
  computed: {
    ...mapGetters(["externalSoftware"]),
  },
  watch: {},
  mounted() {
    this.refresh();
  },
  methods: {
    refresh: function () {},
  },
};
</script>
<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped></style>
